<template>
  <div class="home">
       <div class="content">
           <div class="header">
             <div class="al">
                 <div class="hleft">
               <img :src="info.logo" alt="">
                </div>
                <div class="hright">
                      <div>{{info.name}}</div>
                      <div>热度：{{info.heat}}</div>
                </div>
              </div>
             <div style="margin-top: 2px;
                margin-left: 569px;">
                            <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select">
                <el-select style="width:100px"  v-model="select" slot="prepend" placeholder="请选择">
                  <el-option v-for="(item,index) in tabs" :key="index" :label="item" :value="index"></el-option>
                </el-select>
                <el-button @click="search()" slot="append" style="color: #FFF;
                background-color: #409EFF;
                border-color: #409EFF;
                border-radius:0 ;
                height:40px
            }" type="primary">确认</el-button>
              </el-input>
            </div>
           </div>
           <div class="xiacont">
                <el-tabs style="width:99%" v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="话题" name="first"></el-tab-pane>
                    <el-tab-pane label="频道" name="second"></el-tab-pane>
                    <el-tab-pane label="小视频" name="third"></el-tab-pane>
                  </el-tabs>
                <!--导航按钮 -->
                <div v-if="activeName=='first'" class="buttons">
                      <div class="button" v-for="(item,index) in buttons" :class="btnj==item.id?'aa':''" @click="btnx(item.id)" :key="index">
                          {{item.name}}
                      </div>
                </div>
                <!--视频列表-->
                <div class="a" v-if="activeName=='first'">
                    <div v-if="nei.length!=0" class="contners">
                    <div v-for="(item,index) in nei" :key="index" @click="bofan(item)" class="contner">
                        <div class="topt">
                            <img :src="item.cover" alt="" class="fen img">
                            <div class="info" v-if="activeName!='second'">
                                <!-- <div v-show="activeName=='first'" class="sta">{{item.status}}</div> -->
                                <div class="sth">{{item.heat}}热度</div>
                            </div>
                        </div>
                        <div class="bottomt">
                          <div class="title">{{item.name}}</div>
                          <div v-if="activeName=='first'" class="time">更新时间：{{item.insert_time}}</div>
                          <div v-if="activeName=='second'" class="time">已更新{{item.video}}个话题</div>
                        </div>
                    </div>
                </div>
                  <el-empty style="margin-top:75px" v-else :image-size="150"></el-empty>
                  <div class="load" v-if="loads">加载中...</div>
                </div>


                <div class="b"  v-if="activeName=='second'">
                    <div v-if="nei2.length!=0" class="contners">
                    <div v-for="(item,index) in nei2" :key="index" @click="bofan(item)" class="contner">
                        <div class="topt">
                            <img :src="item.cover" alt="" class="fen img">
                            <div class="info" v-if="activeName!='second'">
                                <!-- <div v-show="activeName=='first'" class="sta">{{item.status}}</div> -->
                                <div class="sth">{{item.heat}}热度</div>
                            </div>
                        </div>
                        <div class="bottomt">
                          <div class="title">{{item.name}}</div>
                          <div v-if="activeName=='first'" class="time">更新时间：{{item.insert_time}}</div>
                          <div v-if="activeName=='second'" class="time">已更新{{item.video}}个话题</div>
                        </div>
                    </div>
                </div>
                  <el-empty style="margin-top:75px" v-else :image-size="150"></el-empty>
                  <div class="load" v-if="loads">加载中...</div>
                </div>

                <div class="c" v-if="activeName=='third'">
                    <div v-if="nei3.length!=0" class="contners">
                    <div v-for="(item,index) in nei3" :key="index" @click="bofan(item)" class="contner">
                        <div class="topt">
                            <img :src="item.cover" alt="" class="fen img">
                            <div class="info" v-if="activeName!='second'">
                                <!-- <div v-show="activeName=='first'" class="sta">{{item.status}}</div> -->
                                <div class="sth">{{item.heat}}热度</div>
                            </div>
                        </div>
                        <div class="bottomt">
                          <div class="title">{{item.name}}</div>
                          <div v-if="activeName=='first'" class="time">更新时间：{{item.insert_time}}</div>
                          <div v-if="activeName=='second'" class="time">已更新{{item.video}}个话题</div>
                        </div>
                    </div>
                </div>
                  <el-empty style="margin-top:75px" v-else :image-size="150"></el-empty>
                  <div class="load" v-if="loads">加载中...</div>
                </div>
            </div>
               <div class="ade">
               <div>由深圳市鹏智慧软件科技有限公司 提供技术支持<a style="padding-right:5px" class="beian" href="http://beian.miit.gov.cn/" target="_blank">粤ICP备20051753号-1</a>2020-{{newyear}}</div>
          </div>
           
       </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
   
  },
   data() {
    //这里存放数据
    return {
      newyear:new Date().getFullYear(),
      loads:false,
      load:true,
      shows:"",
      page:1,
      limit:12,
      type:1,
      keyword:"",
      info:"",
      activeName:"first",
      btnj:0,
      select:0,
      input3:"",
      xux:false,
      buttons:[],
      tabs:['话题','频道','小视频'],
      nei:[],
      nei2:[],
      nei3:[]
    }
   },
     methods: {
       //搜索
       search(){
         this.nei = []
         this.nei2 = []
         this.nei3 = []
         this.page = 1
         this.load =true
          if(this.select==0){
            this.type=1
            this.activeName='first'
            this.init(this.page,this.limit)
          }else if(this.select==1) {
            this.type=2
            this.loads = false
             this.$axios.post('/forward_open/channel_datalist',{
                     keyword:this.keyword
                   }).then(res=>{
                        this.nei = res.data.data
              })
            this.activeName ='second'
          }else {
               this.type=3
            this.activeName='third'
            this.init(this.page,this.limit)
          }
       },
       //初始化
       init(page,limit){
          this.$axios.post('/forward_open/video_datalist',{
           page:page,
           limit:limit,
           type:this.type,
           type_id:this.type==1?this.btnj:"",
           keyword:this.keyword
         }).then(res=>{
                this.neik = true
                if(res.data.data.datalist.length<10){ 
                     this.load = false
                     this.loads =false
                }else {
                  this.loads = true
                     this.page =this.page+1
                }
                if(this.activeName=="first") {
                    this.nei = this.nei.concat(res.data.data.datalist)
                }else if(this.activeName=="second"){
                    this.nei2 = res.data.data.datalist
                }else {
                     this.nei3 = this.nei3.concat(res.data.data.datalist)
                }
         })
       },
       //判断当前设备是手机还是PC
           isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      // localStorage.setItem('isiphone',flag)
      return flag;
    },
         //[话题、频道、小视频]点击
         handleClick(){
              this.page = 1
              this.nei=[]
              this.nei2=[]
              this.nei3=[]
              this.load = true
              if(this.activeName=='first' || this.activeName=='third') {
                 if(this.activeName=='first'){
                    this.type=1
                 }else {
                    this.type=3
                 }
                this.init(this.page,this.limit)
              }else if(this.activeName=='second') {
                this.loads = false
                this.load = false
                this.type=2
                   this.$axios.post('/forward_open/channel_datalist',{
                     keyword:""
                   }).then(res=>{
                        this.nei2 = res.data.data
                   })
              }
         },
         //视频播放
         bofan(e){
            if(this.activeName=='first'){
             this.$router.push({path:'/play',query:{
               id:e.id
             }})
            }else if(this.activeName=='second') {
                  this.$router.push({
                    path:"/pin",
                    query:{
                      id:e.id,
                      play:e.video
                    }
                  })
            }else {
              this.$router.push({path:'/play',query:{
               id:e.id
             }})
            }
         },
         //导航按钮点击
         btnx(e){
           this.page =1
           this.load = true
            this.btnj = e
            this.nei = []
            this.init(this.page,this.limit)
         }
     },
    created() {
      this.$axios.post('/forward_open/company_data').then(res=>{
        this.info = res.data.message
        document.title = this.info.name
        this.$axios.post('/forward_open/topic_datalist').then(res=>{
             this.buttons = res.data.data
             this.init(this.page,this.limit)
        })

      })
    if (this.isMobile()) {
        this.$router.replace('/index')
    }
    },
 
     activated(){
       if(this.info.name!=undefined){
      document.title = this.info.name
       }
       var that = this
      window.onscroll = function(){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
        //滚动条到底部的条件
        if(scrollTop+windowHeight == scrollHeight){
        //到了这个就可以进行业务逻辑加载后台数据了
        //  that.footerText = '我是有底线的';
          if(that.load == true){
               that.init(that.page,that.limit)
          }
        } 
      }
  }
}
</script>
<style scoped>
.load {
  text-align: center;
 padding: 32px 0;
  color: rgb(116, 116, 116);
  padding-top: 0;
}
.ade {
  height: 10px;
  width: 100%;
  text-align: center;
  color: #ccc;
  font-size: 15px;
  padding-bottom: 60px;
}
.trw {
  margin: 20px auto;
}
.buttons {
   display: flex;
}
.home {
  padding-top: 44px;
  box-sizing: border-box;
}
.time {
  font-size: 14px;
  line-height: 20px;
  color: #919499;
}
.title {
  margin: 15px 0;
  margin-bottom: 7px;
  text-align: left;
  font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #202124;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.sta {
  width: 80px;
    min-width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #006cff;
}
.sth {
    width: 70px;
    min-width: 100px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    background: rgba(32,33,36,.6);
    border-radius: 0 0 4px 0;
}
.buttons {
  margin: 10px 0px;
}
.topt {
  width:100%; height: 173px;
  overflow: hidden;
  position: relative;
}
.info {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
.contner {
  cursor:pointer;
  width:25%;
  padding-right: 20px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.img{width:100%;
 height: 100%;
transition: all 1.2s;}
.img:hover{transform: scale(1.2);}
.fen {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
}
.contners {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}
.aa {
  color: #fff !important;
  background: #006cff;
  border: 1px solid transparent !important;
}

.button {
      box-sizing: border-box;
    padding: 0 10px;
    cursor: pointer;
    margin-right: 1.5%;
    margin-bottom: 12px;
    min-width: 7%;
    height: 32px;
    font-size: 14px;
    color: #bfc2cc;
    text-align: center;
    line-height: 32px;
    border-radius: 16px;
    border: 1px solid #bfc2cc;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
     .el-input__suffix  {
       right:30px !important;
     }
    .content {
       width: 1227px;
       margin: 0 auto;
      display: flex;
      flex-direction: column;
      min-height: 95vh;
    }
    .al {
      display: flex;
    }
    .al img {
      width: 50px;
      height: 50px;
    }
    .hright {
      margin-left: 10px;
      margin-top: 5px;
    }
    .hright div {
      text-align: left;
    }
     .hright div:nth-child(1) {
      font-weight: 700;
      color: #202124;
    }
      .hright div:nth-child(2) {
      font-size: 14px;
    color: #919499;
    }
    .ar {
          display: flex;
    }
    .ar input {
      height: 40px;
      width: 190px;
    }
    .xiacont {
      flex: 1;
    }
    .header {
      height: 60px;
      display: flex;
      margin-bottom: 27px;
    }
    input{  
	background:none;  
	outline:none;  
	border:1px solid #ccc;
}
   
</style>
