import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Input, Tabs,TabPane,Select,Option,empty} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'video.js/dist/video-js.css'
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(empty)
import "swiper/dist/css/swiper.css";
import axios from 'axios';
Vue.prototype.$axios = axios
Vue.config.productionTip = false
// import Meta from 'vue-meta'
// Vue.use(Meta)
// import Video from 'video.js'
// import 'video.js/dist/video-js.css'
// Vue.prototype.$video = Video
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
