import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import(/* webpackChunkName: "about" */ '../components/zhong.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true // 需要被缓存
    }
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../components/index.vue'),
    meta: {
      keepAlive: true // 需要被缓存
    }
  },
  {
    path: '/playM',
    name: 'playM',
    component: () => import(/* webpackChunkName: "about" */ '../components/playerM.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../components/search.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/pinm',
    name: 'pinm',
    component: () => import(/* webpackChunkName: "about" */ '../components/pinM.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/play',
    name: 'play',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/player.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  },
  {
    path: '/pin',
    name: 'pin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/pin.vue'),
    meta: {
      keepAlive: false // 需要被缓存
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
