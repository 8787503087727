<template>
  <div class="as" v-if="reFresh2" id="app">
     <transition name="fade">
       <keep-alive>
        <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
        </router-view>
      </keep-alive>
       </transition>
      <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件，比如 Text -->
      </router-view>
      
  </div>
</template>
<script>
export default {
    data(){
      return{
       icon:"",
       reFresh2:true
      } 
    },
     created(){
       //获取并设置页面标题、图标
             this.$axios.post('/forward_open/company_data').then(res=>{
              this.icon = res.data.message.logo
              if(this.icon!=""){
                   var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
                link.type = "image/x-icon";
                link.rel = "shortcut icon";
                link.href = this.icon;  //icon图标
                document.getElementsByTagName("head")[0].appendChild(link);
              }
                this.reFresh2= false
                  this.$nextTick(()=>{
                    this.reFresh2 = true
                })
            })
       }

}
</script>
<style>
  .vjs-paused.vjs-has-started .vjs-big-play-button {
 display:block; 
} 
  
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2           .1.8 */ {
  opacity: 0;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: url("./assets/44.png") no-repeat;
  background-size: 1920px 1520px;
}
body {
  margin: 0;
   -webkit-overflow-scrolling: touch;
   overflow-x: hidden;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.el-select .el-input {
    width: 130px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
    border-color: #bfc2cc !important;
    border-radius: 0 !important;
    border-right: 1px solid #bfc2cc !important;
  }
.input-with-select .el-input__inner {
    border-color: #bfc2cc !important;
    border-left: none !important;
    border-right: none !important;
} 
 .el-input__suffix  {
       right:30px !important;
     }

</style>
